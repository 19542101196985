import { FC } from 'react';
import { faPenToSquare, faTrash } from '~/assets';
import { formatShortDateFromString } from '~/utils';
import { Card, IconSearch, Row, RowContent, SearchIconBox } from './styles';

type Props = {
  campaign: Campaign;
  toggleEditCampaignModal: () => void;
  setCurrentCampaign: (campaign: Campaign) => void;
  toggleRemoveCampaignModal: () => void;
};

const CampaignsRow: FC<Props> = ({
  campaign,
  toggleEditCampaignModal,
  setCurrentCampaign,
  toggleRemoveCampaignModal,
}) => {
  const { name, startDate, endDate, points, bonus } = campaign;
  const handleEditCampaign = () => {
    setCurrentCampaign(campaign);
    toggleEditCampaignModal();
  };
  const handleRemoveCampaign = () => {
    setCurrentCampaign(campaign);
    toggleRemoveCampaignModal();
  };
  return (
    <>
      <Card>
        <Row>
          <RowContent>{name}</RowContent>
        </Row>
        <Row>
          <RowContent>{formatShortDateFromString(startDate)}</RowContent>
        </Row>
        <Row>
          <RowContent>{formatShortDateFromString(endDate)}</RowContent>
        </Row>
        <Row>
          <RowContent>{points}</RowContent>
        </Row>
        <Row>
          <RowContent>{bonus}</RowContent>
        </Row>
        <Row>
          <SearchIconBox id="onPress_editCampaign" onPress={handleEditCampaign}>
            <IconSearch icon={faPenToSquare} />
          </SearchIconBox>
        </Row>
        <Row>
          <SearchIconBox
            id="onPress_removeCampaign"
            onPress={handleRemoveCampaign}
            transparent
          >
            <IconSearch icon={faTrash} />
          </SearchIconBox>
        </Row>
      </Card>
    </>
  );
};

export default CampaignsRow;
