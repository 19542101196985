import { ShopApi } from '~/api';
import { action, makeObservable, observable } from '~/modules';

export default class ShopsStore {
  @observable
  shopsList: ListResponse<Shop> = {};

  @observable
  currentShop: Shop = {} as Shop;

  constructor() {
    makeObservable(this);
  }

  @action
  setCurrentShop = (shop: Shop) => {
    this.currentShop = shop;
  };

  @action
  setShopsList = (shopsList: ListResponse<Shop>) => {
    this.shopsList = shopsList;
  };

  @action
  getShops = async (values: GetShopProps): Promise<void> => {
    this.setShopsList(await ShopApi.getShops(values));
  };

  @action
  editShop = async (id: string, editShopParams: EditShop): Promise<void> => {
    await ShopApi.editShops(id, editShopParams);
  };
}
