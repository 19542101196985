import styled from 'styled-components';
import { Icon, getTheme, pxToRem } from '~/modules';
import Button from '../Button';
import Typography from '../Typography';

const brandPrimaryContrast = getTheme('brand.primary.contrast');
const backgroundZ4 = getTheme('background.z4');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const themeRadiusCard = getTheme('themeRadius.card');
const borderRadiusXl = getTheme('borderRadius.xl');
const brandPrimaryLight = getTheme('brand.primary.light');
const brandPrimaryMain = getTheme('brand.primary.main');
export const Row = styled.div`
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-right: ${spacingMd}px;
`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 2fr 1fr;
  align-items: center;
  width: 100%;
  padding: 0 ${spacingLg}px;
  background: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;

  & + & {
    margin-top: ${spacingSm}px;
  }
  cursor: pointer;
`;

export const RowContent = styled(Typography).attrs({ variant: 'md' })`
  flex: 1;
  font-weight: bold;
  margin-right: ${spacingMd}px;
`;

export const RowContentColumn = styled.div`
  flex-direction: column;
  gap: ${spacingSm}px;
  margin-top: ${spacingMd}px;
  margin-bottom: ${spacingMd}px;
`;

export const RowContentColumnLine = styled.div`
  flex-direction: row;
  gap: ${spacingSm}px;
`;

export const RowContentColumnLabel = styled(Typography).attrs({
  variant: 'xs',
})`
  font-weight: bold;
`;

export const RowContentColumnValue = styled(Typography).attrs({
  variant: 'xs',
})``;

export const RowButtonEdit = styled(Row)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const SearchIconBox = styled(Button)`
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  border-radius: ${borderRadiusXl}px;
  background: linear-gradient(
    180deg,
    ${brandPrimaryLight} 0%,
    ${brandPrimaryMain} 100%
  );
  cursor: pointer;
`;

export const IconSearch = styled(Icon)`
  font-size: ${pxToRem(24)};
  color: ${brandPrimaryContrast};
  font-weight: 900;
`;
