import { FormValidator, isValidCpf, isValidEmail } from '~/modules';
import { UserRoles, validateName } from '~/utils';

export const validationSchema = FormValidator.object().shape({
  name: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Nome inválido', validateName),
  role: FormValidator.string().required('Campo obrigatório'),
  document: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'CPF inválido', isValidCpf),
  email: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Email inválido', isValidEmail),
  metadata: FormValidator.object().shape({
    locationCode: FormValidator.string().when('role', ([role]) => {
      return role === UserRoles.RH_LOJA
        ? FormValidator.string().required('Campo obrigatório')
        : FormValidator.string().notRequired();
    }),
  }),
});

export const getInitialValues = (values: User): EditUser => {
  return {
    id: values.id,
    name: values.name,
    document: values.document,
    email: values.email,
    role: values.role,
    metadata: {
      locationCode: values.metadata?.locationCode,
    },
  };
};
