import { PaginatedList, SceneWrapper } from '~/components';
import { Icon, getTheme, pxToRem, styled } from '~/modules';

const spacingMd = getTheme('spacing.md');
const spacingXxl = getTheme('spacing.xxl');

export const Wrapper = styled(SceneWrapper)`
  flex: 1;
`;

export const ContentBox = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const TopBox = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-top: ${spacingXxl}px;
  margin-bottom: ${spacingMd}px;
`;

export const IconPlus = styled(Icon).attrs({
  fontSize: pxToRem(20),
})`
  margin-right: ${spacingMd}px;
`;

export const ShopsPaginatedList = styled(PaginatedList)`
  grid-template-columns: 1fr 3fr 3fr 2fr 1fr;
`;
