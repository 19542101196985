import { ListPagination, ResponseError } from '~/utils';
import request from '../request';

export const getShops = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
}: GetShopProps): Promise<ListResponse<Shop>> => {
  try {
    const { data } = await request.get('/locations', {
      params: {
        page,
        size,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const editShops = async (id: string, editShopParams: EditShop) => {
  try {
    await request.patch(`/locations/${id}`, editShopParams);
  } catch (error) {
    throw new ResponseError(error);
  }
};
