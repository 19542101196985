import type { FC } from 'react';
import { faPlus } from '~/assets';
import { PaginatedList, TitlePage } from '~/components';
import QrCodeRow from '~/components/QrCodeRow';
import type { Routes } from '~/routes';
import { qrCodeColumns } from '~/utils';
import {
  ButtonGenerateQrCode,
  ContentBox,
  IconPlus,
  TopBox,
  Wrapper,
} from './styles';

type Props = {
  areQrCodesLoading: boolean;
  qrCodesList: any;
  onChangePage(page: number): void;
  onNavigate(route: Routes): void;
  onGenerateQrCode: (locationCode: string) => Promise<void>;
  locationCode?: string;
};

const QrCode: FC<Props> = ({
  areQrCodesLoading,
  qrCodesList,
  onChangePage,
  onGenerateQrCode,
  locationCode,
}: Props) => {
  return (
    <Wrapper titlePage="Qr Code">
      <ContentBox>
        <TopBox>
          <TitlePage>QR Codes</TitlePage>
          <ButtonGenerateQrCode
            onLoading={areQrCodesLoading}
            id="btn_register_qr_code"
            onPress={() => {
              locationCode && onGenerateQrCode(locationCode);
            }}
            secondary
          >
            <IconPlus icon={faPlus} />
            Gerar QR Code
          </ButtonGenerateQrCode>
        </TopBox>

        <PaginatedList
          columns={qrCodeColumns}
          list={qrCodesList.content}
          totalPages={qrCodesList.paging?.totalPages}
          currentPage={qrCodesList.paging?.page}
          isLoading={areQrCodesLoading}
          onChangePage={onChangePage}
          renderList={(qrCode: QrCode) => (
            <QrCodeRow
              status={qrCode?.status}
              createdAt={qrCode?.createdAt}
              code={qrCode?.code}
              key={`${qrCode.locationCode}_${qrCode.code}`}
            />
          )}
          titleEmptyData="Sem QR Codes disponíveis"
        />
      </ContentBox>
    </Wrapper>
  );
};

export default QrCode;
