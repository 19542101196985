import { FC } from 'react';
import { Card, Row, RowValue } from './styles';

type Props = {
  position?: number;
  name?: string;
  points?: number;
};
const UsersRankingRow: FC<Props> = ({ name = '', position, points }) => {
  return (
    <Card>
      <Row>
        <RowValue>{position}º</RowValue>
      </Row>
      <Row>
        <RowValue>{name}</RowValue>
      </Row>
      <Row>
        <RowValue>{points}</RowValue>
      </Row>
    </Card>
  );
};

export default UsersRankingRow;
