import type { FC } from 'react';
import { TitlePage } from '~/components';
import ShopRow from '~/components/ShopRow';
import { shopColumns } from '~/utils';
import { ContentBox, ShopsPaginatedList, TopBox, Wrapper } from './styles';

type Props = {
  onChangePage(page: number): void;
  areShopsLoading: boolean;
  shopsList: ListResponse<Shop>;
  toggleEditShopModal: () => void;
  setCurrentShop: (shop: Shop) => void;
};

const Shops: FC<Props> = ({
  onChangePage,
  areShopsLoading,
  shopsList,
  toggleEditShopModal,
  setCurrentShop,
}: Props) => {
  return (
    <Wrapper titlePage="Lojas">
      <ContentBox>
        <TopBox>
          <TitlePage totalElements={shopsList?.paging?.totalElements}>
            Lojas
          </TitlePage>
        </TopBox>

        <ShopsPaginatedList
          columns={shopColumns}
          list={shopsList.content}
          totalPages={shopsList.paging?.totalPages}
          currentPage={shopsList.paging?.page}
          isLoading={areShopsLoading}
          onChangePage={onChangePage}
          renderList={(shop: Shop) => (
            <ShopRow
              shop={shop}
              toggleEditShopModal={toggleEditShopModal}
              setCurrentShop={setCurrentShop}
              key={`${shop.latitude}_${shop.longitude}`}
            />
          )}
          titleEmptyData="Sem lojas disponíveis"
        />
      </ContentBox>
    </Wrapper>
  );
};

export default Shops;
