import { Button, SceneWrapper, Typography } from '~/components';
import { Icon, getTheme, pxToRem, styled } from '~/modules';
import { mqDevices } from '~/utils';

const spacingXs = getTheme('spacing.xs');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');
const themeRadiusCard = getTheme('themeRadius.card');
const boxShadowTertiaryGlow = getTheme('boxShadow.z4');
const backgroundZ3 = getTheme('background.z3');
const backgroundZ4 = getTheme('background.z4');
const borderRadiusMax = getTheme('borderRadius.max');

export const Wrapper = styled(SceneWrapper)`
  flex: 1;
`;

export const ContentBox = styled.div`
  flex: 1;
  padding: ${spacingXxl}px 0;

  @media ${mqDevices.inMobileAndTablet} {
    flex-direction: column;
  }
`;

export const PaginatedListBox = styled.div`
  flex: 1;
  flex-direction: column;
  margin-left: ${spacingLg}px;

  @media ${mqDevices.inMobileAndTablet} {
    margin-left: 0;
    margin-top: ${spacingXxl}px;
  }
`;

export const Title = styled(Typography).attrs({ variant: 'md' })`
  font-weight: bold;
`;

export const Column = styled.div`
  flex-direction: column;
  gap: 32px;
  flex: 1;
  max-width: ${pxToRem(400)};

  @media ${mqDevices.inMobileAndTablet} {
    max-width: 100%;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: fit-content;
  flex-direction: column;
  max-width: ${pxToRem(400)};
  padding: ${spacingLg}px;
  background-color: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;
  margin-bottom: ${spacingXl}px;
  gap: ${spacingMd}px;
  ${boxShadowTertiaryGlow}

  @media ${mqDevices.inMobileAndTablet} {
    height: fit-content;
    max-width: 100%;
    margin-right: ${spacingMd}px;
  }
`;

export const SmallCard = styled.div`
  font-weight: 500;
  opacity: 0.8 !important;
  background-color: ${backgroundZ3};
  border-radius: ${themeRadiusCard}px;
  padding: ${spacingMd}px;
  width: 100%;
  flex-direction: column;
`;

export const SmallCardWrapper = styled.div`
  flex-direction: column;
  gap: ${spacingMd}px;
`;

export const Label = styled(Typography).attrs({
  variant: 'xs',
})`
  font-weight: 700;
  opacity: 0.5 !important;
  margin-bottom: ${spacingXs}px;
`;

export const DescriptionLabel = styled(Typography).attrs({ variant: 'xs' })`
  font-weight: 500;
`;

export const ButtonComponent = styled(Button)`
  width: 100%;
  min-height: ${pxToRem(50)};
  border-radius: ${borderRadiusMax}px;
`;

export const IconComponent = styled(Icon)`
  font-size: ${pxToRem(17)};
  margin-right: ${spacingMd}px;
`;
