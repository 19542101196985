import { FC } from 'react';
import { faPenToSquare } from '~/assets';
import { formatShopWorkingHours } from '~/utils';
import If from '../If';
import {
  Card,
  IconSearch,
  Row,
  RowButtonEdit,
  RowContent,
  RowContentColumn,
  RowContentColumnLabel,
  RowContentColumnLine,
  RowContentColumnValue,
  SearchIconBox,
} from './styles';

type Props = {
  shop: Shop;
  toggleEditShopModal: () => void;
  setCurrentShop: (shop: Shop) => void;
};

const ShopRow: FC<Props> = ({ toggleEditShopModal, shop, setCurrentShop }) => {
  const { name, code, workingHours, latitude, longitude } = shop;

  const handleEditShopPress = () => {
    setCurrentShop(shop);
    toggleEditShopModal();
  };

  return (
    <>
      <Card>
        <Row>
          <RowContent>{code}</RowContent>
        </Row>
        <Row>
          <RowContent>{name}</RowContent>
        </Row>
        <Row>
          <RowContentColumn>
            <If condition={!!formatShopWorkingHours(workingHours)}>
              {formatShopWorkingHours(workingHours)?.map((dayArray) => (
                <RowContentColumnLine>
                  <RowContentColumnLabel>
                    {dayArray?.weekDayPtBr}:{' '}
                  </RowContentColumnLabel>
                  <RowContentColumnValue>
                    {dayArray?.startTime} - {dayArray?.endTime}
                  </RowContentColumnValue>
                </RowContentColumnLine>
              ))}
            </If>
          </RowContentColumn>
        </Row>
        <Row>
          <RowContentColumn>
            <RowContentColumnLine>
              <RowContentColumnLabel>Latitude: </RowContentColumnLabel>
              <RowContentColumnValue>{latitude}</RowContentColumnValue>
            </RowContentColumnLine>
            <RowContentColumnLine>
              <RowContentColumnLabel>Longitude: </RowContentColumnLabel>
              <RowContentColumnValue>{longitude}</RowContentColumnValue>
            </RowContentColumnLine>
          </RowContentColumn>
        </Row>
        <RowButtonEdit>
          <SearchIconBox id="onPress_shop" onPress={handleEditShopPress}>
            <IconSearch icon={faPenToSquare} />
          </SearchIconBox>
        </RowButtonEdit>
      </Card>
    </>
  );
};

export default ShopRow;
