import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { ModalCampaign, ModalConfirmation } from '~/components';
import { observer } from '~/modules';
import { formatToApiDate, showAlert, useStores } from '~/utils';
import Campaigns from './Campaigns';

export const CampaignsContainer: FC = () => {
  const {
    campaigns: {
      campaignsList,
      getCampaigns,
      setCurrentCampaign,
      currentCampaign,
      editCampaign,
      deleteCampaign,
      createCampaign,
    },
    shops: { getShops, shopsList },
  } = useStores();
  const [areCampaignsLoading, setAreCampaignsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [areShopsLoading, setAreShopsLoading] = useState(false);
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false);
  const [isRemoveCampaignModalOpen, setIsRemoveCampaignModalOpen] =
    useState(false);
  const [campaignSubmittedSuccesfully, setCampaignSubmittedSuccesfully] =
    useState(false);

  const onGetCampaigns = async (values) => {
    setAreCampaignsLoading(true);
    try {
      await getCampaigns(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setAreCampaignsLoading(false);
    }
  };

  const onGetShops = async (values) => {
    setAreShopsLoading(true);
    try {
      await getShops(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setAreShopsLoading(false);
    }
  };

  useEffect(() => {
    onGetCampaigns({});
    onGetShops({});
  }, []);

  const toggleCampaignModal = (editMode: boolean) => {
    setIsEdit(editMode);
    setIsCampaignModalOpen(true);
  };

  const closeCampaignModal = () => {
    setIsCampaignModalOpen(false);
    setCurrentCampaign({} as Campaign);
  };

  const toggleRemoveCampaignModal = () => {
    setIsRemoveCampaignModalOpen((state) => !state);
  };

  const handleSubmit = async (campaign: Campaign) => {
    const parsedStartDate = moment(campaign?.startDate, 'DD/MM/YYYY').toDate();
    const apiStartDate = formatToApiDate(parsedStartDate);
    const parsedEndDate = moment(campaign?.endDate, 'DD/MM/YYYY').toDate();
    const apiEndDate = formatToApiDate(parsedEndDate);
    const formattedCampaign: Campaign = {
      ...campaign,
      startDate: apiStartDate,
      endDate: apiEndDate,
      locations: campaign.locations ?? [],
    };
    try {
      isEdit
        ? await editCampaign(formattedCampaign)
        : await createCampaign(formattedCampaign);
      showAlert({
        message: `Campanha ${isEdit ? 'atualizada' : 'criada'} com sucesso`,
        type: 'success',
      });
      setCampaignSubmittedSuccesfully(true);
      setCurrentCampaign({} as Campaign);
      onGetCampaigns({});
    } catch ({ message }) {
      showAlert({ message, type: 'error' });
    } finally {
      closeCampaignModal();
    }
  };

  const handleSubmitDelete = async () => {
    try {
      await deleteCampaign(currentCampaign?.id);
      showAlert({ message: 'Campanha deletada com sucesso', type: 'success' });
      setCurrentCampaign({} as Campaign);
      onGetCampaigns({});
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      toggleRemoveCampaignModal();
    }
  };

  return (
    <>
      <Campaigns
        campaignsList={campaignsList}
        areCampaignsLoading={areCampaignsLoading}
        onChangePage={(page) => onGetCampaigns({ page })}
        toggleCampaignModal={toggleCampaignModal}
        toggleRemoveCampaignModal={toggleRemoveCampaignModal}
        setCurrentCampaign={setCurrentCampaign}
      />
      <ModalCampaign
        handleClose={closeCampaignModal}
        handleSubmit={handleSubmit}
        isOpen={isCampaignModalOpen}
        currentCampaign={currentCampaign}
        shopsList={shopsList}
        areShopsLoading={areShopsLoading}
        onGetShops={(page) => onGetShops({ page })}
        isEdit={isEdit}
        setCampaignSubmittedSuccesfully={setCampaignSubmittedSuccesfully}
        campaignSubmittedSuccesfully={campaignSubmittedSuccesfully}
      />
      <ModalConfirmation
        isOpen={isRemoveCampaignModalOpen}
        onConfirm={handleSubmitDelete}
        onCancel={toggleRemoveCampaignModal}
        description="Você tem certeza que deseja cancelar essa campanha?"
      />
    </>
  );
};

export default observer(CampaignsContainer);
