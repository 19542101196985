import { useEffect, useState } from 'react';
import ModalEditShop from '~/components/ModalEditShop';
import { observer } from '~/modules';
import { showAlert, useStores } from '~/utils';
import Shops from './Shops';

export const ShopsContainer = () => {
  const {
    shops: { shopsList, getShops, setCurrentShop, currentShop, editShop },
  } = useStores();

  const [areShopsLoading, setAreShopsLoading] = useState(true);
  const [isEditShopModalOpen, setIsEditShopModalOpen] = useState(false);

  const onGetShops = async (values: GetShopProps) => {
    setAreShopsLoading(true);
    try {
      await getShops(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setAreShopsLoading(false);
    }
  };

  useEffect(() => {
    onGetShops({});
  }, []);

  const toggleEditShopModal = () => setIsEditShopModalOpen((state) => !state);

  const handleFormSubmit = async (values: EditShop) => {
    // Remove the empty objects
    const filteredWorkingHours = Object.fromEntries(
      Object.entries(values.workingHours).filter(([, value]: any) =>
        Object.values(value).some((v) => v !== null && v !== undefined),
      ),
    );
    try {
      await editShop(currentShop?.id, {
        name: values.name,
        latitude: values.latitude,
        longitude: values.longitude,
        workingHours: filteredWorkingHours,
      });
      onGetShops({ page: shopsList?.paging?.page });
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      toggleEditShopModal();
    }
  };

  return (
    <>
      <Shops
        shopsList={shopsList}
        areShopsLoading={areShopsLoading}
        onChangePage={(page) => onGetShops({ page })}
        toggleEditShopModal={toggleEditShopModal}
        setCurrentShop={setCurrentShop}
      />
      <ModalEditShop
        isOpen={isEditShopModalOpen}
        currentShop={currentShop}
        handleClose={toggleEditShopModal}
        handleFormSubmit={handleFormSubmit}
      />
    </>
  );
};

export default observer(ShopsContainer);
