import { FC } from 'react';
import { faPlus } from '~/assets';
import { CampaignsRow, TitlePage } from '~/components';
import { observer } from '~/modules';
import { campaignColumns } from '~/utils';
import {
  ButtonCreateCampaign,
  CampaignPaginatedList,
  ContentBox,
  IconPlus,
  TopBox,
  Wrapper,
} from './styles';
type Props = {
  areCampaignsLoading: boolean;
  campaignsList: ListResponse<Campaign>;
  onChangePage(page: number): void;
  toggleCampaignModal: (isEdit: boolean) => void;
  setCurrentCampaign: (campaign: Campaign) => void;
  toggleRemoveCampaignModal: () => void;
};
export const Campaigns: FC<Props> = ({
  areCampaignsLoading,
  campaignsList,
  onChangePage,
  toggleCampaignModal,
  setCurrentCampaign,
  toggleRemoveCampaignModal,
}) => {
  return (
    <Wrapper titlePage="Campanhas">
      <ContentBox>
        <TopBox>
          <TitlePage totalElements={campaignsList.paging?.totalElements}>
            Campanhas
          </TitlePage>
          <ButtonCreateCampaign
            onLoading={areCampaignsLoading}
            id="btn_create_campaign"
            onPress={() => toggleCampaignModal(false)}
            secondary
          >
            <IconPlus icon={faPlus} />
            Criar Nova Campanha
          </ButtonCreateCampaign>
        </TopBox>
        <CampaignPaginatedList
          columns={campaignColumns}
          list={campaignsList.content}
          totalPages={campaignsList.paging?.totalPages}
          currentPage={campaignsList.paging?.page}
          isLoading={areCampaignsLoading}
          onChangePage={onChangePage}
          renderList={(campaign: Campaign) => (
            <CampaignsRow
              campaign={campaign}
              toggleEditCampaignModal={() => toggleCampaignModal(true)}
              setCurrentCampaign={setCurrentCampaign}
              toggleRemoveCampaignModal={toggleRemoveCampaignModal}
              key={`${campaign.id}_${campaign.name}`}
            />
          )}
          titleEmptyData="Sem campanhas cadastradas"
        />
      </ContentBox>
    </Wrapper>
  );
};

export default observer(Campaigns);
