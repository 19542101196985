import { Icon, getTheme, pxToRem, styled } from '~/modules';
import { mqDevices } from '~/utils';
import Button from '../Button';
import Modal from '../Modal';
import TextInput from '../TextInput';
import Typography from '../Typography';

const backgroundZ3 = getTheme('background.z3');
const boxShadowZ4 = getTheme('boxShadow.z4');

const spacingXxl = getTheme('spacing.xxl');
const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');

const backgroundZ4 = getTheme('background.z4');
const themeRadiusCard = getTheme('themeRadius.card');

const textMain = getTheme('text.main');
const borderWidthMin = getTheme('borderWidth.min');

export const Wrapper = styled(Modal)`
  flex-direction: column;
  max-height: 95%;
  width: ${pxToRem(959)};
  max-width: 90%;
  background-color: ${backgroundZ3};
  ${boxShadowZ4};

  @media ${mqDevices.inMobileAndTablet} {
    height: 85%;
  }
`;

export const Form = styled.div`
  width: 100%;
  padding-top: ${spacingXxl}px;
  padding-bottom: 0;
  flex-direction: column;
  gap: ${spacingMd}px;
  overflow: auto;
`;

export const Section = styled.div`
  flex-direction: column;
  gap: ${spacingSm}px;
  margin-bottom: ${spacingLg}px;
  padding-inline: ${spacingXxl}px;
`;

export const SectionRow = styled.div`
  gap: ${spacingLg}px;
`;

export const Input = styled(TextInput)``;

export const SectionTitle = styled(Typography).attrs({ variant: 'lg' })`
  font-weight: bold;
  margin-bottom: ${spacingSm}px;
`;

export const WorkingHoursSection = styled.div`
  flex-direction: column;
  gap: ${spacingSm}px;
  margin-bottom: ${spacingSm}px;
`;

export const WorkingHoursLabel = styled(Typography).attrs({ variant: 'md' })`
  font-weight: bold;
`;

export const WorkingHoursValues = styled.div`
  gap: ${spacingLg}px;
`;

export const Footer = styled.div`
  width: 100%;
  flex: 1;
  position: sticky;
  bottom: 0;
  gap: ${spacingLg}px;
  justify-content: center;
  align-items: center;
  padding-inline: ${spacingXxl}px;
  min-height: ${pxToRem(106)};
  background-color: ${backgroundZ4};
  border-bottom-left-radius: ${themeRadiusCard}px;
  border-bottom-right-radius: ${themeRadiusCard}px;
  border-top: ${borderWidthMin}px solid ${textMain}35;
`;

export const ButtonComponent = styled(Button)`
  width: 100%;
`;

export const ButtonAddWorkingHours = styled(Button)`
  width: fit-content;
  padding-inline: ${pxToRem(24)};
  gap: ${pxToRem(12)};
  align-items: center;
  margin-top: ${spacingSm}px;
`;

export const AddIcon = styled(Icon).attrs({
  fontSize: pxToRem(20),
})``;

export const ButtonRemoveWorkingHours = styled(Button)`
  padding-inline: ${pxToRem(0)};
  width: ${pxToRem(108)};
  margin-top: auto;
`;

export const RemoveIcon = styled(Icon)`
  font-size: ${pxToRem(24)};
`;
