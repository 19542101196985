import { ListPagination, ResponseError } from '~/utils';
import request from '../request';

export const getCampaigns = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
}: GetCampaignProps): Promise<ListResponse<Campaign>> => {
  try {
    const { data } = await request.get('/campaigns', {
      params: {
        page,
        size,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const editCampaign = async (editCampaignParams: Campaign) => {
  try {
    await request.put(
      `/campaigns/${editCampaignParams?.id}`,
      editCampaignParams,
    );
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const createCampaign = async (campaign: Campaign) => {
  try {
    await request.post('/campaigns', campaign);
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const deleteCampaign = async (id: string) => {
  try {
    await request.delete(`/campaigns/${id}`);
  } catch (error) {
    throw new ResponseError(error);
  }
};
