import { FC, useEffect, useState } from 'react';
import { observer } from '~/modules';
import { showAlert, useStores } from '~/utils';
import UsersRanking from './UsersRanking';

export const UsersRankingContainer: FC = () => {
  const {
    users: { usersRankingList, getUsersRanking },
  } = useStores();
  const [isUsersRankingLoading, setIsUsersRankingLoading] = useState(false);

  const onGetUsersRanking = async (values: GetUsersRankingProps) => {
    setIsUsersRankingLoading(true);
    try {
      await getUsersRanking(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsUsersRankingLoading(false);
    }
  };

  useEffect(() => {
    onGetUsersRanking({});
  }, []);
  return (
    <UsersRanking
      isUsersRankingLoading={isUsersRankingLoading}
      usersRankingList={usersRankingList}
      onChangePage={(page: number) => onGetUsersRanking({ page })}
    />
  );
};

export default observer(UsersRankingContainer);
