import { Button, PaginatedList, SceneWrapper } from '~/components';
import { Icon, getTheme, pxToRem, styled } from '~/modules';

const spacingMd = getTheme('spacing.md');
const spacingXxl = getTheme('spacing.xxl');
const boxShadowSecondaryGlow = getTheme('boxShadow.secondary glow');

export const Wrapper = styled(SceneWrapper)`
  flex: 1;
`;

export const ContentBox = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const TopBox = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-top: ${spacingXxl}px;
  margin-bottom: ${spacingMd}px;
`;

export const CampaignPaginatedList = styled(PaginatedList)`
  grid-template-columns: 5fr 3fr 3fr 3fr 3fr 2fr 2fr;
`;

export const ButtonCreateCampaign = styled(Button)`
  width: ${pxToRem(300)};
  height: ${pxToRem(70)};
  ${boxShadowSecondaryGlow}
`;

export const IconPlus = styled(Icon).attrs({
  fontSize: pxToRem(20),
})`
  margin-right: ${spacingMd}px;
`;
