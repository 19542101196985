import { FC } from 'react';
import { faLock, faShieldHalved, faUnlock, faXmark } from '~/assets';
import { OptionButton, OptionsList, UserCardInfo } from '~/components';
import { Routes } from '~/routes';
import {
  AlertMessages,
  EventOptionsAccount,
  UserRolesPt,
  UserStatus,
  maskCpfOrCnpj,
  parseEnumToPt,
} from '~/utils';
import { ContentBox, ContentColumn, Wrapper } from './styles';

type Props = {
  user: User;
  onPressEditData(): void;
  onNavigate(route: Routes): void;
  onPressOption(option: EventOptionsAccount, text: AlertMessages): void;
};

const UserDetails: FC<Props> = ({
  user,
  onNavigate,
  onPressOption,
  onPressEditData,
}) => {
  const isActive = user.status === UserStatus.ACTIVE;
  const isCanceled = user.status === UserStatus.CANCELLED;
  const { ACTIVE, BLOCKED, CANCEL } = EventOptionsAccount;
  const { BLOCK_USER_MESSAGE, UNBLOCK_USER_MESSAGE, CANCEL_USER_MESSAGE } =
    AlertMessages;

  return (
    <Wrapper titlePage="Dados do usuário">
      <ContentBox>
        <UserCardInfo
          name={user.name}
          userName={user.username}
          status={user.status}
          email={user.email}
          document={maskCpfOrCnpj(user.document)}
          role={parseEnumToPt(user.role, UserRolesPt)}
          onPressEditData={onPressEditData}
        />
        <ContentColumn>
          <OptionsList condition={!isCanceled}>
            <OptionButton
              id="btn_lock_account"
              label={isActive ? 'Bloquear usuário' : 'Desbloquear usuário'}
              icon={isActive ? faLock : faUnlock}
              onPress={() =>
                onPressOption(
                  isActive ? BLOCKED : ACTIVE,
                  isActive ? BLOCK_USER_MESSAGE : UNBLOCK_USER_MESSAGE,
                )
              }
            />
            <OptionButton
              id="role_products"
              label="Permissões de Produtos"
              icon={faShieldHalved}
              onPress={() => onNavigate(Routes.PRODUCTS_PERMISSION)}
            />
            <OptionButton
              id="btn_cancel_user"
              label="Cancelar usuário"
              icon={faXmark}
              onPress={() => onPressOption(CANCEL, CANCEL_USER_MESSAGE)}
            />
          </OptionsList>
        </ContentColumn>
      </ContentBox>
    </Wrapper>
  );
};

export default UserDetails;
