import styled from 'styled-components';
import { Icon, getTheme, pxToRem } from '~/modules';
import Button from '../Button';
import Typography from '../Typography';

const brandPrimaryContrast = getTheme('brand.primary.contrast');
const backgroundZ4 = getTheme('background.z4');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const themeRadiusCard = getTheme('themeRadius.card');
const borderRadiusXl = getTheme('borderRadius.xl');
export const Row = styled.div`
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-right: ${spacingMd}px;
`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 5fr 3fr 3fr 3fr 3fr 2fr 2fr;
  align-items: center;
  width: 100%;
  min-height: ${pxToRem(110)};
  padding: 0 ${spacingLg}px;

  background: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;

  & + & {
    margin-top: ${spacingSm}px;
  }
`;

export const RowContent = styled(Typography).attrs({ variant: 'md' })`
  font-weight: bold;
  flex: 1;
  margin-right: ${spacingMd}px;
  line-height: 120%;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const SearchIconBox = styled(Button)`
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  border-radius: ${borderRadiusXl}px;
  cursor: pointer;
`;

export const IconSearch = styled(Icon)`
  font-size: ${pxToRem(24)};
  color: ${brandPrimaryContrast};
  font-weight: 900;
`;
