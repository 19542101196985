import { FC } from 'react';
import { FormikProps, useFormikContext } from '~/modules';
import { RolesValueSelect, UserRoles, UserStatus } from '~/utils';
import HeaderModal from '../HeaderModal';
import If from '../If';
import PersonInfo from '../PersonInfo';
import Select from '../Select';
import {
  ButtonComponent,
  ButtonResetPassword,
  Content,
  Footer,
  Form,
  Input,
  Wrapper,
  WrapperInfo,
} from './styles';

type Props = {
  isOpen: boolean;
  userSelected: User;
  handleClose(): void;
  onResetPassword(): void;
};

const ModalRegisterUser: FC<Props> = ({
  isOpen,
  userSelected,
  handleClose,
  onResetPassword,
}) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    submitForm,
    setFieldValue,
    submitCount,
  }: FormikProps<EditUser> = useFormikContext();

  const getStoreError = () => {
    // There is a bug in the Formik library that sets an object type as string
    const metadataErrors = errors?.metadata as UserMetadata; // With this cast I'm able to get the metadata errors object properly
    return submitCount > 0 ? metadataErrors?.locationCode : undefined;
  };

  return (
    <Wrapper isOpen={isOpen}>
      <HeaderModal title="Dados do usuário" onPressClose={handleClose} />
      <Content>
        <WrapperInfo>
          <PersonInfo
            name={userSelected.name}
            status={userSelected.status}
            userName={userSelected.username}
          />
          <ButtonResetPassword
            id="btn_reset_password"
            onPress={onResetPassword}
            disabled={userSelected.status !== UserStatus.ACTIVE}
          >
            Redefinir Senha
          </ButtonResetPassword>
        </WrapperInfo>
        <Form>
          <Input
            label="Nome completo"
            placeholder="Digite o nome do usuário"
            fullWidth
            id="name"
            name="name"
            error={touched?.name && errors?.name}
            value={values.name}
            onChange={handleChange}
          />
          <Input
            label="CPF"
            placeholder="000.000.000-00"
            fullWidth
            id="document"
            name="document"
            maskType="cpf"
            error={touched?.document && errors?.document}
            value={values.document}
            onChange={handleChange}
          />
          <Input
            label="E-mail"
            placeholder="Digite o email do usuário"
            fullWidth
            id="email"
            name="email"
            error={touched?.email && errors?.email}
            value={values.email}
            onChange={handleChange}
          />
          <Select
            label="Permissão"
            placeholder="Selecione a permissão do usuário"
            id="role"
            name="role"
            error={touched?.role && errors?.role}
            onChange={({ name, value }) => setFieldValue(name, value)}
            value={values.role}
            options={RolesValueSelect}
          />
          <If condition={values?.role === UserRoles.RH_LOJA}>
            <Input
              label="Loja"
              placeholder="Selecione a loja do usuário"
              fullWidth
              id="metadata.locationCode"
              name="metadata.locationCode"
              error={getStoreError()}
              onChange={handleChange}
              value={values?.metadata?.locationCode || ''}
            />
          </If>
        </Form>
      </Content>
      <Footer>
        <ButtonComponent id="btn_cancel_edit" onPress={handleClose} tertiary>
          Cancelar
        </ButtonComponent>
        <ButtonComponent
          id="btn_edit"
          onPress={submitForm}
          onKeyEnter
          transparent
        >
          Confirmar
        </ButtonComponent>
      </Footer>
    </Wrapper>
  );
};

export default ModalRegisterUser;
