import { FC } from 'react';
import { faPrint } from '~/assets';
import { QRCodeCanvas } from '~/modules';
import { QrCodeStatusEnum, formatToScreenDate } from '~/utils';
import If from '../If';
import {
  Card,
  IconSearch,
  PrintArea,
  QrCodeValue,
  Row,
  RowButtonSearch,
  RowContent,
  SearchIconBox,
} from './styles';

type Props = {
  status: string;
  createdAt: Date;
  code: string;
};

const QrCodeRow: FC<Props> = ({ status, createdAt, code }) => {
  return (
    <>
      <Card>
        <Row>
          <RowContent>{code}</RowContent>
        </Row>
        <Row>
          <RowContent>
            {status === QrCodeStatusEnum.ACTIVE ? 'Ativo' : 'Inativo'}
          </RowContent>
        </Row>
        <Row>
          <RowContent>{formatToScreenDate(createdAt)}</RowContent>
        </Row>
        <RowButtonSearch>
          <SearchIconBox
            id="onPress_qrCode"
            onPress={() => window.print()}
            disabled={status === QrCodeStatusEnum.INACTIVE}
          >
            <IconSearch icon={faPrint} />
          </SearchIconBox>
        </RowButtonSearch>
        <If condition={status != QrCodeStatusEnum.INACTIVE}>
          <PrintArea>
            <QRCodeCanvas value={code} size={450} />
            <QrCodeValue>{code}</QrCodeValue>
          </PrintArea>
        </If>
      </Card>
    </>
  );
};

export default QrCodeRow;
