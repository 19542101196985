import { Icon, getTheme, ifStyle, pxToRem, styled } from '~/modules';
import Button from '../Button';
import Modal from '../Modal';
import PaginatedList from '../PaginatedList';
import TextInput from '../TextInput';
import Typography from '../Typography';

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');

const backgroundZ4 = getTheme('background.z4');
const themeRadiusCard = getTheme('themeRadius.card');

const textMain = getTheme('text.main');
const borderWidthMin = getTheme('borderWidth.min');

const themeRadiusInput = getTheme('themeRadius.input');
const brandPrimaryMain = getTheme('brand.primary.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');

const isFullWidth = ifStyle('fullwidth');
const isSelected = ifStyle('isSelected');

export const Wrapper = styled(Modal)`
  flex-direction: column;
  width: ${pxToRem(959)};
  max-width: 90%;
  max-height: 95vh;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  padding: ${spacingXl}px;
`;

export const Input = styled(TextInput)`
  margin-bottom: ${spacingMd}px;
`;

type ShopTagProps = {
  fullwidth?: boolean;
  isSelected: boolean;
};

export const ShopTag = styled.div<ShopTagProps>`
  font-weight: 700;
  align-items: center;
  padding-left: ${pxToRem(16)};
  width: ${isFullWidth('100%', 'fit-content')};
  height: ${pxToRem(48)};
  border: ${borderWidthMin}px solid ${brandPrimaryMain};
  border-radius: ${themeRadiusInput}px;
  color: ${brandPrimaryContrast};
  background: ${isSelected(brandPrimaryMain, 'transparent')};
  cursor: pointer;
  justify-content: space-between;
`;

export const Footer = styled.div`
  width: 100%;
  flex: 1;
  position: sticky;
  bottom: 0;
  gap: ${spacingLg}px;
  justify-content: center;
  align-items: center;
  padding-inline: ${spacingXxl}px;
  min-height: ${pxToRem(106)};
  background-color: ${backgroundZ4};
  border-bottom-left-radius: ${themeRadiusCard}px;
  border-bottom-right-radius: ${themeRadiusCard}px;
  border-top: ${borderWidthMin}px solid ${textMain}35;
`;

export const ButtonComponent = styled(Button)`
  width: 100%;
`;

export const ShopsSection = styled.div`
  flex-direction: column;
`;

export const ShopsTitle = styled(Typography).attrs({ variant: 'sm' })`
  font-weight: 500;
  margin: ${spacingSm}px 0 -${spacingSm}px 0;
`;

export const ButtonOpenShops = styled(Button)`
  width: fit-content;
  padding-inline: ${pxToRem(16)};
  gap: ${spacingSm}px;
`;

export const ButtonOpenShopsIcon = styled(Icon)``;

export const IconComponent = styled(Icon)`
  margin: 0 ${spacingMd}px;
`;

export const ShopsWrapper = styled.div`
  margin: ${spacingMd}px 0;
  gap: ${spacingMd}px;
  flex-wrap: wrap;
`;

export const ShopsListWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  padding: ${spacingXl}px;
  gap: ${spacingMd}px;
`;

export const ShopsListContent = styled.div`
  flex-direction: column;
  gap: ${spacingMd}px;
`;

export const ShopsListTitle = styled(Typography).attrs({
  variant: 'md',
})`
  font-weight: 500;
`;

export const CampaignShopsPaginatedList = styled(PaginatedList)`
  grid-template-columns: 2fr 7fr 1fr;
`;
