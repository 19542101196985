import { FC } from 'react';
import { faPlus, faTrash } from '~/assets';
import { FormikProps, useFormikContext } from '~/modules';
import { formatShopWorkingHours } from '~/utils';
import HeaderModal from '../HeaderModal';
import If from '../If';
import {
  AddIcon,
  ButtonAddWorkingHours,
  ButtonComponent,
  ButtonRemoveWorkingHours,
  Footer,
  Form,
  Input,
  RemoveIcon,
  Section,
  SectionRow,
  SectionTitle,
  WorkingHoursLabel,
  WorkingHoursSection,
  WorkingHoursValues,
  Wrapper,
} from './styles';

type Props = {
  isOpen: boolean;
  currentShop: Shop;
  handleClose(): void;
};

const ModalEditShop: FC<Props> = ({ isOpen, currentShop, handleClose }) => {
  const { name } = currentShop;
  const {
    values,
    errors,
    touched,
    handleChange,
    submitForm,
    setFieldValue,
  }: FormikProps<EditShop> = useFormikContext();

  const removeWorkingHours = (day: string) => {
    setFieldValue(`workingHours.${day}.startTime`, undefined);
    setFieldValue(`workingHours.${day}.endTime`, undefined);
  };

  const addWorkingHours = (day: string) => {
    setFieldValue(`workingHours.${day}.startTime`, '00:00');
    setFieldValue(`workingHours.${day}.endTime`, '23:00');
  };

  return (
    <Wrapper isOpen={isOpen}>
      <HeaderModal title={name} onPressClose={handleClose} />
      <Form>
        <Section>
          <SectionTitle>Dados da loja</SectionTitle>
          <SectionRow>
            <Input
              id="code_id"
              name="code"
              value={values.code}
              label="Código"
              disabled
            />
            <Input
              label="Nome completo"
              placeholder="Digite o nome do usuário"
              fullWidth
              id="name"
              name="name"
              error={touched?.name && errors?.name}
              value={values.name}
              onChange={handleChange}
            />
          </SectionRow>
          <SectionRow>
            <Input
              label="Latitude"
              placeholder="Latitude"
              fullWidth
              id="latitude"
              name="latitude"
              error={touched?.latitude && errors?.latitude}
              value={values.latitude}
              onChange={handleChange}
            />
            <Input
              label="Longitude"
              placeholder="Longitude"
              fullWidth
              id="longitude"
              name="longitude"
              error={touched?.longitude && errors?.longitude}
              value={values.longitude}
              onChange={handleChange}
            />
          </SectionRow>
        </Section>
        <Section>
          <SectionTitle>Horário de funcionamento</SectionTitle>
          <If
            condition={
              !!formatShopWorkingHours(values?.workingHours) &&
              !!values?.workingHours
            }
          >
            {formatShopWorkingHours(values?.workingHours)?.map((dayArray) => (
              <>
                <If
                  condition={
                    values.workingHours[dayArray.weekDayEn]?.startTime !=
                      undefined &&
                    values.workingHours[dayArray.weekDayEn]?.endTime !=
                      undefined
                  }
                >
                  <WorkingHoursSection>
                    <WorkingHoursLabel>
                      {dayArray?.weekDayPtBr}
                    </WorkingHoursLabel>
                    <WorkingHoursValues>
                      <Input
                        type="time"
                        label="Início"
                        placeholder="Digite o horário de início"
                        fullWidth
                        id={`workingHours.${dayArray.weekDayEn}.startTime`}
                        name={`workingHours.${dayArray.weekDayEn}.startTime`}
                        value={
                          values.workingHours[dayArray.weekDayEn]?.startTime
                        }
                        onChange={handleChange}
                      />
                      <Input
                        type="time"
                        label="Término"
                        placeholder="Digite o horário de fim"
                        fullWidth
                        id={`workingHours.${dayArray.weekDayEn}.endTime`}
                        name={`workingHours.${dayArray.weekDayEn}.endTime`}
                        value={values.workingHours[dayArray.weekDayEn]?.endTime}
                        onChange={handleChange}
                      />
                      <ButtonRemoveWorkingHours
                        id="btn_remove_working_hours"
                        onPress={() => removeWorkingHours(dayArray.weekDayEn)}
                        transparent
                      >
                        <RemoveIcon icon={faTrash} />
                      </ButtonRemoveWorkingHours>
                    </WorkingHoursValues>
                  </WorkingHoursSection>
                </If>
                <If
                  condition={
                    !values.workingHours[dayArray.weekDayEn]?.startTime &&
                    !values.workingHours[dayArray.weekDayEn]?.endTime
                  }
                >
                  <WorkingHoursSection>
                    <WorkingHoursLabel>
                      {dayArray?.weekDayPtBr}
                    </WorkingHoursLabel>
                    <ButtonAddWorkingHours
                      id="btn_add_working_hours"
                      onPress={() => addWorkingHours(dayArray.weekDayEn)}
                    >
                      <AddIcon icon={faPlus} />
                      Adicionar horário de funcionamento
                    </ButtonAddWorkingHours>
                  </WorkingHoursSection>
                </If>
              </>
            ))}
          </If>
        </Section>
        <Footer>
          <ButtonComponent id="btn_cancel_edit" onPress={handleClose} tertiary>
            Cancelar
          </ButtonComponent>
          <ButtonComponent
            id="btn_confir_edit"
            onPress={submitForm}
            onKeyEnter
            transparent
          >
            Confirmar
          </ButtonComponent>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default ModalEditShop;
