export enum Routes {
  LOGIN = '/login',
  USER_ACTIVATION_ERROR = '/user-activation-error',

  HOME = '/',

  USERS = '/users',
  USER_DETAILS = '/users/details',
  PRODUCTS_PERMISSION = '/users/details/product-permission',

  CLIENTS = '/clients',
  CLIENT_REGISTER = '/clients/register',
  CLIENT_SEARCH = '/clients/search',
  COMPANY_REGISTER = '/clients/company/register',
  CLIENT_ACCOUNTS = '/clients/accounts',
  CLIENT_ACCOUNT_DETAILS = '/clients/accounts/details',
  ADD_BALANCE = '/clients/accounts/side-account/add-balance',
  UPDATE_MILIT = '/clients/accounts/side-account/update-limit',
  SIDE_ACCOUNT_REGISTER = '/clients/accounts/side-account/register',

  PRODUCTS = '/products',
  PRODUCT_REGISTER = '/products/register',
  PRODUCT_DETAILS = '/products/details',

  FILES = '/uploads',
  FILES_DETAILS = '/uploads/details',

  EXPORTS = '/exports',

  SETTINGS = '/settings',

  QR_CODE = '/qr-code',
  USERS_RANKING = '/users-ranking',

  SHOPS = '/shops',
  CAMPAIGNS = '/campaigns',
}

export const RoutesHistory = {
  [Routes.LOGIN]: '',
  [Routes.USER_ACTIVATION_ERROR]: '',

  [Routes.HOME]: '',

  [Routes.USERS]: '',
  [Routes.USER_DETAILS]: 'Usuários / ',
  [Routes.PRODUCTS_PERMISSION]: 'Usuários / Dados do Usuário / ',

  [Routes.CLIENTS]: '',
  [Routes.CLIENT_REGISTER]: 'Clientes /',
  [Routes.COMPANY_REGISTER]: 'Clientes /',
  [Routes.CLIENT_SEARCH]: 'Clientes /',
  [Routes.CLIENT_ACCOUNTS]: 'Clientes / Buscar Cliente /',
  [Routes.CLIENT_ACCOUNT_DETAILS]:
    'Clientes / Buscar Cliente / Contas do Cliente / ',
  [Routes.ADD_BALANCE]: 'Clientes / Adicionar Saldo / ',
  [Routes.UPDATE_MILIT]: 'Clientes / Atualizar Limite / ',
  [Routes.SIDE_ACCOUNT_REGISTER]:
    'Clientes / Buscar Cliente / Contas do Cliente / ',

  [Routes.PRODUCTS]: '',
  [Routes.PRODUCT_REGISTER]: 'Produtos / ',
  [Routes.PRODUCT_DETAILS]: 'Produtos / ',

  [Routes.FILES]: '',
  [Routes.FILES_DETAILS]: 'Uploads / ',

  [Routes.EXPORTS]: '',

  [Routes.SETTINGS]: '',
};
