import { FC } from 'react';
import { TitlePage, UsersRankingRow } from '~/components';
import { observer } from '~/modules';
import { usersRankingColumns } from '~/utils';
import {
  ContentBox,
  TopBox,
  UsersRankingPaginatedList,
  Wrapper,
} from './styles';
type Props = {
  isUsersRankingLoading: boolean;
  usersRankingList: ListResponse<UsersRanking>;
  onChangePage(page: number): void;
};
export const UsersRanking: FC<Props> = ({
  isUsersRankingLoading,
  usersRankingList,
  onChangePage,
}) => {
  return (
    <Wrapper titlePage="Ranking">
      <ContentBox>
        <TopBox>
          <TitlePage>Ranking de usuários</TitlePage>
        </TopBox>
        <UsersRankingPaginatedList
          columns={usersRankingColumns}
          list={usersRankingList.content}
          totalPages={usersRankingList.paging?.totalPages}
          currentPage={usersRankingList.paging?.page}
          isLoading={isUsersRankingLoading}
          onChangePage={onChangePage}
          renderList={(user: UsersRanking) => (
            <UsersRankingRow
              name={user.name}
              position={user.locationPosition}
              points={user.points}
              key={`${user.name}` + `${user.locationPosition}`}
            />
          )}
          titleEmptyData="Sem usuários cadastrados"
        />
      </ContentBox>
    </Wrapper>
  );
};

export default observer(UsersRanking);
