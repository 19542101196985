export enum UserRoles {
  ADMIN = 'ADMINISTRADOR',
  SPECTATOR = 'ESPECTADOR',
  RH_LOJA = 'RH_LOJA',
  RH_CENTRAL = 'RH_CENTRAL',
}

export enum UserStatus {
  ACTIVE = 'ATIVO',
  PENDING = 'PENDENTE',
  BLOCKED = 'BLOQUEADO',
  CANCELLED = 'CANCELADO',
}

export enum PermissionType {
  ALLOWED = 'ALLOWED',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

export enum UserRolesPt {
  ADMINISTRADOR = 'Administrador',
  ESPECTADOR = 'Espectador',
  RH_LOJA = 'RH Loja',
  RH_CENTRAL = 'RH Central',
}
