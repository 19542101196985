import { create } from 'mobx-persist';
import CampaignsStore from './campaigns.store';
import ClientsStore from './clients.store';
import ExportsStore from './exports.store';
import ProductsStore from './products.store';
import QrCodesStore from './qrCodes.store';
import ShopsStore from './shops.store';
import ThemeStore from './theme.store';
import TransactionStore from './transactions.store';
import UploadStore from './upload.store';
import UserStore from './user.store';
import UsersStore from './users.store';
import UtilsStore from './utils.store';

class RootStore {
  theme: ThemeStore;

  user: UserStore;

  users: UsersStore;

  uploads: UploadStore;

  exports: ExportsStore;

  clients: ClientsStore;

  products: ProductsStore;

  transactions: TransactionStore;

  utils: UtilsStore;

  qrCodes: QrCodesStore;

  shops: ShopsStore;

  campaigns: CampaignsStore;

  constructor() {
    this.user = new UserStore();
    this.users = new UsersStore();
    this.uploads = new UploadStore();
    this.exports = new ExportsStore();
    this.theme = new ThemeStore();
    this.clients = new ClientsStore();
    this.products = new ProductsStore();
    this.transactions = new TransactionStore();
    this.utils = new UtilsStore();
    this.qrCodes = new QrCodesStore();
    this.shops = new ShopsStore();
    this.campaigns = new CampaignsStore();
  }
}

const hydrate = create({
  storage: localStorage,
});

const store = new RootStore();

hydrate('user', store.user);
hydrate('users', store.users);
hydrate('uploads', store.uploads);
hydrate('exports', store.exports);
hydrate('theme', store.theme);
hydrate('clients', store.clients);
hydrate('products', store.products);
hydrate('transactions', store.transactions);
hydrate('utils', store.utils);
hydrate('qrCodes', store.qrCodes);
hydrate('shops', store.shops);
hydrate('campaigns', store.campaigns);

export {
  ThemeStore,
  UserStore,
  UsersStore,
  ClientsStore,
  ProductsStore,
  TransactionStore,
  UtilsStore,
  UploadStore,
  ExportsStore,
};
export default store;
