import { FormValidator } from '~/modules';
import {
  formatShortDateFromString,
  isAfterDate,
  isValidDateWithFutureDate,
} from '~/utils';

export const validationSchema = FormValidator.object().shape({
  name: FormValidator.string().required('Campo obrigatório'),
  startDate: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Data inválida', isValidDateWithFutureDate),
  endDate: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Data inválida', isValidDateWithFutureDate)
    .test(
      'valid',
      'Data final deve ser posterior à data inicial',
      (value, context) => {
        return isAfterDate(context.parent.startDate, value);
      },
    ),
  points: FormValidator.number().required('Campo obrigatório'),
  bonus: FormValidator.number().notRequired(),
  locations: FormValidator.array().notRequired(),
});

export const getInitialValues = (campaign: Campaign): Campaign => {
  return {
    id: campaign.id,
    name: campaign.name,
    startDate: formatShortDateFromString(campaign.startDate),
    endDate: formatShortDateFromString(campaign.endDate),
    points: campaign.points,
    bonus: campaign.bonus,
    locations: campaign.locations,
  };
};
