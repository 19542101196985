import { EventOptionsAccount, ListPagination, ResponseError } from '~/utils';
import request from '../request';

export const getUsers = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
  name,
}: GetUsersProps): Promise<ListResponse<Users>> => {
  try {
    const { data } = await request.get('/users', {
      params: {
        page,
        size,
        name,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const getPermissionProducts = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
  userId,
  name,
}: GetPermissionProductsProps): Promise<ListResponse<PermissionProduct>> => {
  try {
    const { data } = await request.get(
      `/users/${userId}/products/permissions`,
      {
        params: {
          page,
          size,
          name,
        },
      },
    );
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const setPermissionProducts = async ({
  userId,
  productId,
  isAllowed,
}: SetPermissionProductsProps): Promise<void> => {
  try {
    await request.post(`/users/${userId}/products/permissions`, {
      productId,
      isAllowed,
    });
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const setPermissionAllProducts = async ({
  userId,
  action,
}: SetPermissionAllProductsProps): Promise<void> => {
  try {
    await request.patch(`/users/${userId}/products/permissions/all`, {
      action,
    });
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const editUser = async ({ id, ...rest }: EditUser): Promise<User> => {
  try {
    const { data } = await request.put(`/users/${id}`, { ...rest });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const setStatusUser = async (
  userId: string,
  status?: EventOptionsAccount,
): Promise<User> => {
  try {
    const { data } = await request.patch(`/users/${userId}/status`, {
      status,
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const resetPassword = async (userId: string): Promise<void> => {
  try {
    const { data } = await request.patch(`/users/${userId}/passwords/reset`);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const getUsersRanking = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
}: GetUsersRankingProps): Promise<ListResponse<UsersRanking>> => {
  try {
    const { data } = await request.get('/ranking', {
      params: {
        page,
        size,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};
