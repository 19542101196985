import { PaginatedList, SceneWrapper } from '~/components';
import { getTheme, styled } from '~/modules';

const spacingMd = getTheme('spacing.md');
const spacingXxl = getTheme('spacing.xxl');

export const Wrapper = styled(SceneWrapper)`
  flex: 1;
`;

export const ContentBox = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const UsersRankingPaginatedList = styled(PaginatedList)`
  grid-template-columns: 1fr 4fr 2fr;
`;

export const TopBox = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-top: ${spacingXxl}px;
  margin-bottom: ${spacingMd}px;
`;
