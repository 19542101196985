import { FormValidator } from '~/modules';

export const validationSchema = FormValidator.object().shape({
  code: FormValidator.string().notRequired(),
  name: FormValidator.string().required('Campo obrigatório'),
  latitude: FormValidator.string().required('Campo obrigatório'),
  longitude: FormValidator.string().required('Campo obrigatório'),
  workingHours: FormValidator.object().notRequired(),
});

export const getInitialValues = (shop: Shop): EditShop => {
  return {
    code: shop.code,
    name: shop.name,
    latitude: shop.latitude,
    longitude: shop.longitude,
    workingHours: {
      MONDAY: {
        startTime: shop?.workingHours?.MONDAY?.startTime,
        endTime: shop?.workingHours?.MONDAY?.endTime,
      },
      TUESDAY: {
        startTime: shop?.workingHours?.TUESDAY?.startTime,
        endTime: shop?.workingHours?.TUESDAY?.endTime,
      },
      WEDNESDAY: {
        startTime: shop?.workingHours?.WEDNESDAY?.startTime,
        endTime: shop?.workingHours?.WEDNESDAY?.endTime,
      },
      THURSDAY: {
        startTime: shop?.workingHours?.THURSDAY?.startTime,
        endTime: shop?.workingHours?.THURSDAY?.endTime,
      },
      FRIDAY: {
        startTime: shop?.workingHours?.FRIDAY?.startTime,
        endTime: shop?.workingHours?.FRIDAY?.endTime,
      },
      SATURDAY: {
        startTime: shop?.workingHours?.SATURDAY?.startTime,
        endTime: shop?.workingHours?.SATURDAY?.endTime,
      },
      SUNDAY: {
        startTime: shop?.workingHours?.SUNDAY?.startTime,
        endTime: shop?.workingHours?.SUNDAY?.endTime,
      },
    },
  };
};
