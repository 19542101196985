import { FormValidator, isValidCpf, isValidEmail } from '~/modules';
import { UserRoles, validateName } from '~/utils';

export const validationSchema = FormValidator.object().shape({
  name: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Nome inválido', validateName),
  role: FormValidator.string().required('Campo obrigatório'),
  document: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'CPF inválido', isValidCpf),
  email: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Email inválido', isValidEmail),
  metadata: FormValidator.object()
    .shape({
      locationCode: FormValidator.string(),
    })
    .when('role', {
      is: UserRoles.RH_LOJA,
      then: (schema) =>
        schema.shape({
          locationCode: FormValidator.string().required('Campo obrigatório'),
        }),
      otherwise: (schema) =>
        schema.shape({
          locationCode: FormValidator.string().notRequired(),
        }),
    }),
});

export const initialValues: RegisterUser = {
  name: '',
  document: '',
  email: '',
  role: '',
  metadata: {
    locationCode: '',
  },
};
