import { UsersApi } from '~/api';
import { action, makeObservable, observable, persist } from '~/modules';
import { AlertMessages, EventOptionsAccount, UserStatus } from '~/utils';

export default class UsersStore {
  @observable
  usersList: ListResponse<Users> = {};

  @persist('object')
  @observable
  userSelected: User = {} as User;

  @observable
  permissionProductsList: ListResponse<PermissionProduct> = {};

  @observable
  usersRankingList: ListResponse<UsersRanking> = {};

  constructor() {
    makeObservable(this);
  }

  @action
  getUsers = async (values: GetUsersProps): Promise<void> => {
    this.usersList = await UsersApi.getUsers(values);
  };

  @action
  setUserSelected = async (values: User) => {
    this.userSelected = values;
  };

  @action
  getPermissionProducts = async (
    values: GetPermissionProductsProps,
  ): Promise<void> => {
    this.permissionProductsList = await UsersApi.getPermissionProducts(values);
  };

  @action
  setPermissionProducts = async (
    values: SetPermissionProductsProps,
  ): Promise<void> => {
    await UsersApi.setPermissionProducts(values);
  };

  @action
  editUser = async (values: EditUser): Promise<void> => {
    this.userSelected = await UsersApi.editUser(values);
  };

  @action
  setStatusUser = async (
    userId: string,
    optionSelected?: EventOptionsAccount,
  ): Promise<string> => {
    this.userSelected = await UsersApi.setStatusUser(userId, optionSelected);

    let message = '';
    switch (optionSelected) {
      case EventOptionsAccount.BLOCKED:
        message = AlertMessages.SUCCESS_BLOCK_USER;
        break;
      case EventOptionsAccount.ACTIVE:
        message = AlertMessages.SUCCESS_UNBLOCK_USER;
        break;
      case EventOptionsAccount.CANCEL:
        message = AlertMessages.SUCCESS_CANCEL_USER;
        break;
      default:
        break;
    }
    return message;
  };

  @action
  resetPassword = async (userId: string): Promise<void> => {
    await UsersApi.resetPassword(userId);
    this.userSelected.status = UserStatus.PENDING;
  };

  @action
  getUsersRanking = async (values: GetUsersProps): Promise<void> => {
    this.usersRankingList = await UsersApi.getUsersRanking(values);
  };
}
