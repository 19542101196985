import { FC } from 'react';
import { faPlus, faXmark } from '~/assets';
import If from '../If';
import { Card, IconSearch, Row, RowContent, SearchIconBox } from './styles';

type Props = {
  shop: Shop;
  isActive: boolean;
  toggleShopState: (shop: Shop) => void;
};

const CampaignShopsRow: FC<Props> = ({ shop, isActive, toggleShopState }) => {
  const { code, name } = shop;
  return (
    <>
      <Card>
        <Row>
          <RowContent>{code}</RowContent>
        </Row>
        <Row>
          <RowContent>{name}</RowContent>
        </Row>
        <If condition={isActive}>
          <Row>
            <SearchIconBox
              id="onPress_disableShop"
              onPress={() => toggleShopState(shop)}
              transparent
            >
              <IconSearch icon={faXmark} />
            </SearchIconBox>
          </Row>
        </If>
        <If condition={!isActive}>
          <Row>
            <SearchIconBox
              id="onPress_enableShop"
              onPress={() => toggleShopState(shop)}
            >
              <IconSearch icon={faPlus} />
            </SearchIconBox>
          </Row>
        </If>
      </Card>
    </>
  );
};

export default CampaignShopsRow;
