import { CalendarWeekdays } from '../enums';

export const formatShopWorkingHours = (
  workingHours: WorkingHoursByDay,
): FormattedShopWorkingHours => {
  const weekDaysPtBr = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ];
  if (!!workingHours) {
    return Object.entries(workingHours)
      .map(([key, value]) => {
        return {
          index: CalendarWeekdays[key],
          weekDayEn: key,
          weekDayPtBr: weekDaysPtBr[CalendarWeekdays[key]],
          startTime: value.startTime,
          endTime: value.endTime,
        };
      })
      .sort((a, b) => a.index - b.index); // Order array accordingly to the week day
  } else {
    return null;
  }
};
