import { CampaignsApi } from '~/api';
import { action, makeObservable, observable, persist } from '~/modules';

export default class CampaignsStore {
  @observable
  campaignsList: ListResponse<Campaign> = {};

  @persist('object')
  @observable
  currentCampaign: Campaign = {} as Campaign;

  constructor() {
    makeObservable(this);
  }

  @action
  setCurrentCampaign = (campaign: Campaign) => {
    this.currentCampaign = campaign;
  };

  @action
  setCampaignsList = (campaignsList: ListResponse<Campaign>) => {
    this.campaignsList = campaignsList;
  };

  @action
  createCampaign = async (campaign: Campaign): Promise<void> => {
    await CampaignsApi.createCampaign(campaign);
  };

  @action
  editCampaign = async (editCampaignParams: Campaign): Promise<void> => {
    await CampaignsApi.editCampaign(editCampaignParams);
  };

  @action
  getCampaigns = async (values: GetCampaignProps): Promise<void> => {
    this.setCampaignsList(await CampaignsApi.getCampaigns(values));
  };

  @action
  deleteCampaign = async (id: string): Promise<void> => {
    await CampaignsApi.deleteCampaign(id);
  };
}
