export const campaignColumns = [
  'Nome',
  'Data de início',
  'Data de término',
  'Pontuação',
  'Bônus',
  '',
];

export const campaignShopsColumns = ['Código', 'Nome', ''];
