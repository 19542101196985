import { FC, useEffect, useState } from 'react';
import { faPlus, faXmark } from '~/assets';
import { type FormikProps, useFormikContext } from '~/modules';
import { Masks, campaignShopsColumns } from '~/utils';
import CampaignShopsRow from '../CampaignShopsRow';
import HeaderModal from '../HeaderModal';
import If from '../If';
import {
  ButtonComponent,
  ButtonOpenShops,
  ButtonOpenShopsIcon,
  CampaignShopsPaginatedList,
  Content,
  Footer,
  IconComponent,
  Input,
  ShopTag,
  ShopsListTitle,
  ShopsListWrapper,
  ShopsSection,
  ShopsTitle,
  ShopsWrapper,
  Wrapper,
} from './styles';

type Props = {
  isOpen: boolean;
  handleClose(): void;
  shopsList: ListResponse<Shop>;
  isEdit: boolean;
  areShopsLoading: boolean;
  onGetShops: (values) => Promise<void>;
  setCampaignSubmittedSuccesfully: (success) => void;
  campaignSubmittedSuccesfully: boolean;
};

const ModalCampaign: FC<Props> = ({
  isOpen,
  handleClose,
  shopsList,
  isEdit,
  areShopsLoading,
  onGetShops,
  setCampaignSubmittedSuccesfully,
  campaignSubmittedSuccesfully,
}) => {
  const {
    values,
    errors,
    touched,
    submitForm,
    handleChange,
    setFieldValue,
    resetForm,
  }: FormikProps<Campaign> = useFormikContext();
  const [isShowingShops, setIsShowingShops] = useState(false);
  const [temporaryLocations, setTemporaryLocations] = useState<Shop[]>();

  const disabledShopState = (shop) => {
    const filteredShops = values?.locations?.filter(
      (location) => location.id !== shop.id,
    );
    setFieldValue('locations', filteredShops);
  };

  const toggleTemporaryLocationState = (shop: Shop) => {
    setTemporaryLocations((prev) => {
      const isActive = prev?.some((location) => location.id === shop.id);
      if (isActive) {
        return prev?.filter((location) => location.id !== shop.id) ?? [];
      } else {
        return [...(prev ?? []), shop];
      }
    });
  };

  const isShopActive = (shop: Shop): boolean =>
    temporaryLocations?.some((location) => location.id === shop.id) ?? false;

  const confirmShopEdit = () => {
    setFieldValue('locations', temporaryLocations);
    setIsShowingShops(false);
  };

  useEffect(() => {
    setTemporaryLocations(values?.locations ?? []);
  }, [values?.locations]);

  useEffect(() => {
    setIsShowingShops(false);
    if (!isOpen && campaignSubmittedSuccesfully) {
      resetForm();
      setCampaignSubmittedSuccesfully(false);
    }
  }, [isOpen]);

  return (
    <Wrapper isOpen={isOpen} onClose={handleClose}>
      <HeaderModal
        title={`${isEdit ? 'Editar' : 'Criar'} Campanha`}
        onPressClose={handleClose}
      />
      <If condition={!isShowingShops}>
        <Content>
          <Input
            label="Nome"
            placeholder="Nome da campanha"
            id="name"
            name="name"
            error={touched.name && errors.name}
            value={values.name}
            onChange={handleChange}
          />
          <Input
            label="Data inicial"
            placeholder="00/00/0000"
            id="startDate"
            name="startDate"
            error={touched.startDate && errors.startDate}
            value={values.startDate}
            mask={Masks.date}
            onChange={handleChange}
          />
          <Input
            label="Data final"
            placeholder="00/00/0000"
            id="endDate"
            name="endDate"
            error={touched.endDate && errors.endDate}
            value={values.endDate}
            mask={Masks.date}
            onChange={handleChange}
          />
          <Input
            label="Pontos"
            placeholder="Pontos"
            id="points"
            name="points"
            type="number"
            error={touched.points && errors.points}
            value={values.points}
            onChange={handleChange}
          />
          <Input
            label="Bônus"
            placeholder="Bônus"
            id="bonus"
            name="bonus"
            type="number"
            error={touched.bonus && errors.bonus}
            value={values.bonus}
            onChange={handleChange}
          />
          <ShopsSection>
            <ShopsTitle>Lojas</ShopsTitle>
            <ShopsWrapper>
              {values?.locations?.map((shop) => (
                <ShopTag onClick={() => disabledShopState(shop)} isSelected>
                  {shop?.name}
                  <IconComponent icon={faXmark} />
                </ShopTag>
              ))}
            </ShopsWrapper>
            <ButtonOpenShops
              id="btn_open_shops"
              onPress={() => setIsShowingShops(true)}
              transparent
            >
              <ButtonOpenShopsIcon icon={faPlus} />
              Adicionar Lojas
            </ButtonOpenShops>
          </ShopsSection>
        </Content>
        <Footer>
          <ButtonComponent id="btn_cancel_edit" onPress={handleClose} tertiary>
            Cancelar
          </ButtonComponent>
          <ButtonComponent
            id="btn_edit"
            onPress={submitForm}
            onKeyEnter
            transparent
          >
            Confirmar
          </ButtonComponent>
        </Footer>
      </If>
      <If condition={isShowingShops}>
        <ShopsListWrapper>
          <ShopsListTitle>Lojas disponíveis</ShopsListTitle>
          <CampaignShopsPaginatedList
            columns={campaignShopsColumns}
            list={shopsList.content}
            totalPages={shopsList.paging?.totalPages}
            currentPage={shopsList.paging?.page}
            isLoading={areShopsLoading}
            onChangePage={onGetShops}
            renderList={(shop: Shop) => {
              return (
                <CampaignShopsRow
                  shop={shop}
                  isActive={isShopActive(shop)}
                  toggleShopState={toggleTemporaryLocationState}
                  key={`${shop.id}`}
                />
              );
            }}
            titleEmptyData="Sem lojas disponíveis"
          />
        </ShopsListWrapper>
        <Footer>
          <ButtonComponent
            id="btn_cancel_edit_shops"
            onPress={() => setIsShowingShops(false)}
            tertiary
          >
            Voltar
          </ButtonComponent>
          <ButtonComponent
            id="btn_edit_shops"
            onPress={confirmShopEdit}
            onKeyEnter
            transparent
          >
            Confirmar
          </ButtonComponent>
        </Footer>
      </If>
    </Wrapper>
  );
};

export default ModalCampaign;
