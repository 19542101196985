import { FC, useMemo } from 'react';
import { FormBehavior } from '~/modules';
import ModalEditShop from './ModalEditShop';
import { getInitialValues, validationSchema } from './schema';

type Props = {
  isOpen: boolean;
  currentShop: Shop;
  handleClose(): void;
  handleFormSubmit: (shop: EditShop) => void;
};

const ModalEditShopContainer: FC<Props> = ({
  isOpen,
  currentShop,
  handleClose,
  handleFormSubmit,
}) => {
  const initialValues = useMemo(() => {
    return getInitialValues(currentShop);
  }, [currentShop]);

  return (
    <FormBehavior
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      enableReinitialize
    >
      <ModalEditShop
        isOpen={isOpen}
        currentShop={currentShop}
        handleClose={handleClose}
      />
    </FormBehavior>
  );
};

export default ModalEditShopContainer;
