import { FC, useMemo } from 'react';
import { FormBehavior } from '~/modules';
import ModalCampaign from './ModalCampaign';
import { getInitialValues, validationSchema } from './schema';

type Props = {
  isOpen: boolean;
  handleClose(): void;
  currentCampaign: Campaign;
  handleSubmit: (campaign: Campaign) => void;
  shopsList: ListResponse<Shop>;
  isEdit: boolean;
  areShopsLoading: boolean;
  onGetShops: (values) => Promise<void>;
  setCampaignSubmittedSuccesfully: (success) => void;
  campaignSubmittedSuccesfully: boolean;
};

const ModalCampaignContainer: FC<Props> = ({
  isOpen,
  handleClose,
  currentCampaign,
  handleSubmit,
  shopsList,
  isEdit,
  areShopsLoading,
  onGetShops,
  setCampaignSubmittedSuccesfully,
  campaignSubmittedSuccesfully,
}) => {
  const initialValues = useMemo(() => {
    return isEdit ? getInitialValues(currentCampaign) : ({} as Campaign);
  }, [currentCampaign, isEdit]);
  return (
    <FormBehavior
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <ModalCampaign
        isOpen={isOpen}
        handleClose={handleClose}
        shopsList={shopsList}
        isEdit={isEdit}
        areShopsLoading={areShopsLoading}
        onGetShops={onGetShops}
        setCampaignSubmittedSuccesfully={setCampaignSubmittedSuccesfully}
        campaignSubmittedSuccesfully={campaignSubmittedSuccesfully}
      />
    </FormBehavior>
  );
};

export default ModalCampaignContainer;
