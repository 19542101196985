import { useEffect, useState } from 'react';
import { observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import { showAlert, useStores } from '~/utils';
import QrCode from './QrCode';

export const QrCodeContainer = () => {
  const navigate = useNavigate();
  const {
    qrCodes: { getQrCodes, qrCodesList, generateQrCode },
    user: { user },
  } = useStores();

  const [areQrCodesLoading, setAreQrCodesLoading] = useState(true);

  const handleNavigate = (route: Routes) => navigate(route);

  const onGetQrCode = async (values: GetQrCodeProps) => {
    setAreQrCodesLoading(true);
    try {
      await getQrCodes(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setAreQrCodesLoading(false);
    }
  };

  const onGenerateQrCode = async (locationCode: string) => {
    setAreQrCodesLoading(true);
    try {
      await generateQrCode(locationCode);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      onGetQrCode({});
    }
  };

  useEffect(() => {
    onGetQrCode({});
  }, []);

  return (
    <QrCode
      areQrCodesLoading={areQrCodesLoading}
      qrCodesList={qrCodesList}
      onChangePage={(page) => onGetQrCode({ page })}
      onGenerateQrCode={(locationCode) => onGenerateQrCode(locationCode)}
      onNavigate={handleNavigate}
      locationCode={user?.metadata?.locationCode}
    />
  );
};

export default observer(QrCodeContainer);
